<template>
  <div id="b2c" class="main-body">
    <div id="nav-white" />
    <div id="services-front" class="airff-bg">
      <h1 class="zoomIn">Air Freight Forwarding</h1>
    </div>

    <div id="content">
      <h1 class="fadeIn">Brief Summary</h1>
      <p class="fadeIn">
        Air Freight involves the transportation of goods by airplane, making it
        the fastest way to ship goods internationally. It’s commonly used for
        time-sensitive, high-value, or perishable items that need to reach their
        destination quickly.
      </p>
    </div>

    <visit-system
      name="B2BPRO"
      link="https://b2bpro.golog.my/login"
      :is-b2b="true"
      class="fadeIn"
    />
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import VisitSystem from "@/components/VisitSystem.vue";

export default {
  components: { VisitSystem },
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".zoomIn", 0.1);
  },
};
</script>
